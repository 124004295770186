<template>
  <div class="flex flex-col items-center justify-center">
    <button
      type="button"
      class="icon-container flex h-28 w-28 cursor-pointer items-center justify-center p-4 xl:h-32 xl:w-32 xl:p-6"
      :aria-label="appData.name"
      :data-testid="`${appData.name}-button`"
      @click="clickOnApp"
    >
      <inline-svg class="h-5/6 w-10/12" :alt="appData.name" :src="getLogoUrl" />
    </button>
    <span class="image-text mt-2">
      {{ appData.name }}
    </span>
  </div>
</template>

<script setup lang="ts">
import type { WorkspaceApp } from '@/types.ts'
import InlineSvg from 'vue-inline-svg'
import { event } from '@/plugins/analytics'
import { computed, ref } from 'vue'
import { IconColors } from './types'
import { useAuthentication } from '@/plugins/authentication'

const props = defineProps<{
  appData: WorkspaceApp
}>()

const authenticationClient = useAuthentication()

const getPublicPathOf = (url: string): string => {
  return new URL(`/${url}`, import.meta?.url).toString()
}

const getLogoUrl = computed(() => {
  return `${getPublicPathOf('appsIcons')}/ic_${props.appData.icon}.svg`
})

const customGradient = computed(() => {
  const product = (props.appData.icon.toUpperCase() ?? 'DEFAULT') as keyof typeof IconColors
  return IconColors[product]?.split(' ')
})

const halfOpacityGradient = ref(`${customGradient.value ? customGradient.value[0] : ''}80`)

const getAppUrl = () => {
  let path = props.appData.url
  if (props.appData?.isSimaDesktop || props.appData?.isSimaVirtualized)
    path += `//authToken/${authenticationClient.getAccessToken()}`
  return path
}

const clickOnApp = () => {
  // Metrics
  event(`click_${props.appData.name.toLowerCase()}`, { event_category: 'application' })
  window.open(getAppUrl(), '_blank')
}
</script>

<style scoped>
.icon-container {
  border-radius: 3rem;
  border: 0.375rem solid transparent;
  background-image: linear-gradient(
    to right,
    v-bind('customGradient[0]'),
    v-bind('customGradient[1]')
  );
  background-clip: padding-box;
}

.icon-container:hover {
  border: 0.375rem solid v-bind('halfOpacityGradient');
  animation: border-pulsate 0.7s;
}

.image-text {
  max-width: 11rem;
  color: #303030;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
}

@keyframes border-pulsate {
  0% {
    border-color: transparent;
  }
  100% {
    border-color: v-bind('halfOpacityGradient');
  }
}
</style>
