import { productFruits } from 'product-fruits'

export const init = ({
  key,
  language,
  user
}: {
  key: string | undefined
  language: string
  user?: { email: string; firstname: string }
}) => {
  if (!key || !user) return
  productFruits.init(key, language, {
    email: user.email,
    username: user.email,
    firstname: user.firstname
  })
}
