<template>
  <div />
</template>
<script setup lang="ts">
import { useAuthentication } from '@/plugins/authentication'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
onMounted(async () => {
  const { redirect, logout } = useAuthentication()
  const usr: any = await redirect()
  const isEmptyUser = JSON.stringify(usr) === JSON.stringify({})
  if (isEmptyUser) {
    return logout()
  }

  router.push({ path: `/`, query: usr?.state })
})
</script>
