<template>
  <div id="landing-container" class="mt-2 flex flex-col items-center justify-center">
    <div
      id="apps-container"
      data-testid="apps-container"
      class="z-10 mt-8 flex flex-row flex-wrap justify-evenly gap-8 overflow-y-scroll"
      :class="maxWidth"
    >
      <div v-for="item in workspaceApps" :key="item.name">
        <app-logo :app-data="item" />
      </div>
    </div>
  </div>
  <sima-footer />
</template>

<script setup lang="ts">
import { AppLogo, SimaFooter } from '@/components/index.ts'
import type { WorkspaceApp } from '@/types.ts'
import { useSettingsStore } from '@/store'
import { computed } from 'vue'

const store = useSettingsStore()
const getApps = () => {
  const param = window.location.href.split('?')[1]?.toLowerCase() ?? ''
  const urlSearchParams = new URLSearchParams(param)
  let wApps: WorkspaceApp[] = store.apps
  if (urlSearchParams.get('showsimavirtualized') !== 'true')
    wApps = wApps.filter((a) => !a.isSimaVirtualized)
  if (urlSearchParams.get('showsimadesktop') !== 'true')
    wApps = wApps.filter((a) => !a.isSimaDesktop)
  return wApps
}
const workspaceApps: WorkspaceApp[] = getApps()

const maxWidth = computed(() => {
  let styleString = ''
  workspaceApps.length === 2 || workspaceApps.length === 4
    ? (styleString = 'max-w-sm')
    : workspaceApps.length >= 3 && workspaceApps.length <= 6
      ? (styleString = 'max-w-xl')
      : workspaceApps.length >= 7 && workspaceApps.length <= 8
        ? (styleString = 'max-w-3xl')
        : (styleString = 'max-w-4xl')
  return styleString
})
</script>

<style scoped>
* {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#landing-container {
  min-height: 60vh;
}
#apps-container div:nth-child(1) {
  animation: fadeIn 0.6s ease-in both 0s;
}
#apps-container div:nth-child(2) {
  animation: fadeIn 0.6s ease-in both 0.15s;
}
#apps-container div:nth-child(3) {
  animation: fadeIn 0.6s ease-in both 0.3s;
}
#apps-container div:nth-child(4) {
  animation: fadeIn 0.6s ease-in both 0.45s;
}
#apps-container div:nth-child(5) {
  animation: fadeIn 0.6s ease-in both 1s;
}
#apps-container div:nth-child(6) {
  animation: fadeIn 0.6s ease-in both 1.15s;
}
#apps-container div:nth-child(7) {
  animation: fadeIn 0.6s ease-in both 1.3s;
}
#apps-container div:nth-child(8) {
  animation: fadeIn 0.6s ease-in both 1.45s;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
</style>
