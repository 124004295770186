import { createApp } from 'vue'
import App from './App.vue'
import { getRouter } from './router'
import { configureSimaSuiteComponents } from 'sima-suite-components-vue3'
import { getSettings, httpMocking, store } from './configuration'
import { analytics, monitoring, auth, translations } from './plugins'

await httpMocking.init()
const settings = await getSettings()
auth.init(settings.authentication)
translations.init()
const app = createApp(App)
store.init(app, settings)
const router = getRouter()
app.use(router)
configureSimaSuiteComponents(app)
analytics.init(app, settings)
monitoring.init(app, router, settings)

app.mount('#app')
