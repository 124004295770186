import { auth } from '@/plugins'
import { Settings } from './types'
import { createPinia } from 'pinia'
import { useSettingsStore } from './store'
import { App } from 'vue'
import { requiredUrls } from './store/settings/types'

export async function getSettings(): Promise<Settings> {
  const headers = {
    'Content-type': 'application/json; charset=UTF-8',
    Authorization: `Bearer ${getToken()}`
  }
  auth.init({
    authority: '',
    clientId: '',
    redirectLogin: '',
    redirectLogout: '',
    silentRenew: '',
    scope: ''
  })
  //TODO: Remove parameter when backend changes always returning sima desktop apps
  const request = await fetch(`./frontendconfiguration.json?showsimadesktop=true`, { headers })
  return await request.json()
}

function getToken() {
  let token = ''

  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i)

    if (key && key.startsWith('oidc.user:') && key.endsWith(':sima-workspace')) {
      token = JSON.parse(sessionStorage[key])?.access_token
      break
    }
  }

  return token
}

export const httpMocking = {
  init: async () => {
    if (process.env.NODE_ENV !== 'development') return

    const { worker } = await import('@/mocks/browser')

    return worker.start({ onUnhandledRequest: 'bypass' })
  }
}

export const store = {
  init: async (app: App<Element>, settings: Settings) => {
    app.use(createPinia())
    const store = useSettingsStore()
    const urls: requiredUrls = {
      changePasswordUrl: settings.changePasswordUrl,
      helpUrl: settings.helpUrl,
      manageUserDataUrl: settings.manageUserDataUrl,
      newsletterUrl: settings.newsletterUrl,
      poweredByUrl: settings.poweredByUrl,
      productFruitKey: settings.productFruitKey
     }
    store.$patch({ apps: settings.workspaceApps, urls })
  }
}
