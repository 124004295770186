import { useAuthentication } from '@/plugins/authentication'

declare global {
  interface Window {
    hideImage: any
  }
}

export async function checkThatUserIsLoggedIn() {
  const { isLoggedIn, signIn } = useAuthentication()
  const isUserLoggedIn = await isLoggedIn()

  if (isUserLoggedIn) {
    window.hideImage()
    return true
  }

  signIn().catch((e) => {
    window.hideImage()
    // eslint-disable-next-line no-console
    console.error(`${e} Authorization service unavailable`)
  })
  return false
}
