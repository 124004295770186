import { AuthenticationConfig, provideAuthentication } from 'sima-suite-authentication'

export { useAuthentication, type AuthenticationConfig } from 'sima-suite-authentication'

export const init = (config: AuthenticationConfig) => {
  const param = window.location.href.split('?')[1]?.toLowerCase() ?? ''
  const urlSearchParams = new URLSearchParams(param)
  config.silentRenew = ''
  config.customState = {
    showsimadesktop: urlSearchParams.get('showsimadesktop') === 'true' ? true : undefined,
    showuserdatamanage: urlSearchParams.get('showuserdatamanage') === 'true' ? true : undefined,
    showsimavirtualized: urlSearchParams.get('showsimavirtualized') === 'true' ? true : undefined
  }
  provideAuthentication(config)
}
