<template>
  <div id="footer-container" class="absolute bottom-0 left-0 w-full">
    <div
      class="absolute left-0 h-full w-full bg-cover bg-top"
      :style="{ backgroundImage: `url(${path}/background_wave.svg)` }"
    />
    <div
      class="absolute left-0 h-full w-full bg-top xl:bg-contain"
      :style="{ backgroundImage: `url(${path}/AIDA_corporative_wave.svg)` }"
    />
    <a
      class="developed_link b2-typography absolute -bottom-0 -left-0 flex h-1/5 w-full items-center justify-center"
      :href="poweredByUrl"
      target="_blank"
      rel="noopener noreferrer"
      :title="descriptionText"
    >
      <span class="pr-1">{{ translate('Home.DevelopedBy') }}</span>
      <img class="developed-default" :src="`${path}/logo_AIDA.svg`" :alt="descriptionText" />
      <img class="developed-hover" :src="`${path}/logo_AIDA_hover.svg`" :alt="descriptionText" />
    </a>
  </div>
</template>

<script setup lang="ts">
import { useTranslator } from '@/plugins/translations'
import { useSettingsStore } from '@/store/settings'
import { computed } from 'vue'

const { translate, language } = useTranslator()
const store = useSettingsStore()
const path = new URL('/footerIcons', import.meta?.url)

const poweredByUrl = computed(
  () => `${store.urls.poweredByUrl}${language.value !== 'es' ? 'en/home' : ''}`
)
const descriptionText = computed(() => `${translate('Home.DevelopedBy')} AIDA`)
</script>

<style scoped>
#footer-container {
  height: 30%;
}
.developed_link {
  text-decoration: none;
  color: #0b0b0c;
  opacity: 0.57;
}
.developed_link:hover {
  opacity: 1;
}
.developed_link:hover .developed-default {
  display: none;
}
.developed_link:hover .developed-hover {
  display: block;
}
.developed-default {
  display: block;
}
.developed-hover {
  display: none;
}
</style>
