import { createRouter, createWebHashHistory, Router } from 'vue-router'
import { AppView, CallbackView, HomeView } from '@/views/'
import { ROUTE_CALLBACK, ROUTE_HOME, ROUTE_MAIN } from './constants'
import { checkThatUserIsLoggedIn } from './navigationGuards'

export const getRouter = (): Router => {
  return createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    routes: [
      {
        path: '/',
        name: ROUTE_MAIN,
        component: AppView,
        redirect: { name: ROUTE_HOME },
        beforeEnter: checkThatUserIsLoggedIn,
        children: [
          {
            path: '/home',
            name: ROUTE_HOME,
            component: HomeView
          }
        ]
      },
      {
        path: '/callback',
        name: ROUTE_CALLBACK,
        component: CallbackView
      }
    ]
  })
}
