import * as Sentry from '@sentry/vue'
import { App } from 'vue'
import { Router } from 'vue-router'
import PackageJson from '../../package.json'
import { Settings } from '@/types'

const productionEnvironments = ['Prod', 'QA']

export const init = (app: App<Element>, router: Router, setttings: Settings) => {
  if (productionEnvironments.includes(setttings.environment ?? '')) {
    Sentry.init({
      release: `${PackageJson.name}@${PackageJson.version}`,
      dsn: setttings.sentryKey ?? '',
      app,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 1.0,
      integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
      trackComponents: true,
      logErrors: true,
      environment: setttings.environment,
      tracePropagationTargets: [setttings.backendUrl]
    })
  }
}
